
type Prices = {
  quantity?: number
  price?: number
}

/**
 * Simple utility to display prices with dollar signs and cents.
 * @param  {Number} price - base price, expected in dollars.
 * @return {String}         Return string will include '$' and denote amount in cents.
 */
export function numberToPrice(price: number) {
  return '$' + fixNum(price).toFixed(2)
}

function fixNum(number: number) {
  return typeof number === 'number' ? number : parseFloat(number)
}

export function getPrice(product: { prices?: Prices[]; price?: number }) {
  return Array.isArray(product.prices) && product.prices.length > 0 ? product.prices[0].price : product.price
}

export function displayPriceFormatter(amount: number) {
  return amount >= 0 ? `${numberToPrice(amount)}` : `-${numberToPrice(amount * -1)}`
}
